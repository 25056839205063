<template>
  <div class="checkIn">
    <Header title="签到" rightTitle="签到规则" @rightFuc="openRule" />
    <!--  广告-->
    <AdvSwiper :advList="advList" class="advSwiper" v-if="advList.length > 0" />
    <div class="signBox">
      <div class="subTitle flex">
        <p>每日签到（{{ grandTotal }}/7）</p>
        <p>
          已经连续签到
          <span>{{ grandTotal }}</span>
          天
        </p>
      </div>
      <div class="checkInBox">
        <div class="signItem" :class="{ signItemed: item.status == 2 || item.status == 3 }" v-for="item in signInList" :key="item.id">
          <ImgDecypt class="prizesImg" :src="item.prizes[0].image" />
          <div class="title">{{ item.prizes[0].name }}</div>
          <div class="day">{{ item.title }}</div>
          <svg-icon v-if="item.status == 3" class="signed" iconClass="signed"></svg-icon>
        </div>
      </div>
      <div class="checkInBtn" @click="taskSing" v-if="!isTodaySigned">签到</div>
      <div class="checkedInBtn flex-center-center" @click="taskSinged" v-else>
        <svg-icon class="signedBtn" iconClass="signedBtn"></svg-icon>
        已签到
      </div>
    </div>
    <!-- 提示弹窗 -->
    <van-popup class="ruleDialog" v-model="ruleShow" :close-on-click-overlay="false">
      <div class="ruleContent">
        <div class="popTitle">签到规则</div>
        <div class="content">
          <p>1.需要每天登陆连续签到，若未连续签到则重新从第一天开始。</p>
          <p>2.连续签到第7日可获得金币礼包，1倍流水即可提现。</p>
        </div>
      </div>
      <div class="closeBtn" @click="ruleShow = false">
        <img src="@/assets/png/promptClose.png" />
      </div>
    </van-popup>
    <!-- 提示弹窗 -->
    <van-popup class="dialog" v-model="promptShow" :close-on-click-overlay="true">
      <div class="dialogContent">
        <div class="dialogTitle">
          <span>温馨提示</span>
        </div>
        <div class="dialogText">
          <span>绑定手机或充值VIP</span>
        </div>
        <div class="btnGroup">
          <img @click="$router.push('/bindPhone')" class="btn toBinding" src="@/assets/png/toBinding.png" />
          <img @click="$router.push('/memberCentre?t=vip')" class="btn toPrepaid" src="@/assets/png/toPrepaid.png" />
        </div>
      </div>
      <div class="closeBtn" @click="promptShow = false">
        <img src="@/assets/png/promptClose.png" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import { queryTask, taskSing } from '@/api/mine';
import { Toast } from 'vant';
import { AdType, getAdItem } from '@/utils/getConfig';
import Header from '@/components/Header';
import ImgDecypt from '@/components/ImgDecypt';
import AdvSwiper from '@/components/AdvSwiper';

export default {
  name: 'CheckIn',
  components: {
    Header,
    ImgDecypt,
    AdvSwiper,
  },
  data() {
    return {
      advList: [], // 广告列表
      signInList: [], // 签到列表
      today: 0, // 当天
      grandTotal: 0, // 累计
      promptShow: false,
      ruleShow: false,
      atCheckInDay: undefined, // 再签到多少天获得VIP
      isTodaySigned: false, // 今天是否签到
    };
  },
  created() {
    this.getList();
    this.advList = getAdItem(AdType.CHECKIN_ADV);
  },
  methods: {
    // 查询列表
    async getList() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(queryTask);
        this.$store.commit('app/SET_LOADING', false);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          this.list = res.data.taskList;
          this.signInList = res.data.signDetails.list;
          this.today = res.data.signDetails.currentValue;
          this.grandTotal = res.data.signDetails.value;
          // 判断今日是否已签到
          let item = this.signInList[this.today - 1];
          if (item.status == 3) {
            this.isTodaySigned = true;
            return;
          }
        } else {
          Toast(res.tip || '查询失败');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('查询失败');
      }
    },
    taskSinged() {
      Toast('你已签到');
    },
    // 签到
    async taskSing() {
      let item = this.signInList[this.today - 1]; // 已签到的
      if (item.status == 3) {
        Toast('你已签到');
        return;
      }
      let req = {
        id: item.id,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(taskSing, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          this.getList();
          Toast(`恭喜你获得${item.prizes[0].name}`);
        } else {
          this.promptShow = true;
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('操作失败');
      }
    },
    handSignInTitle(item, index) {
      if (item.status == 3) {
        return '已签到';
      } else if (this.today == index + 1) {
        return '签到';
      }
      return item.title;
    },
    handNextPrizes() {
      if (this.signInList && this.signInList.length <= 0) {
        return;
      }
      let todayIndex = this.today - 1 < 0 ? 0 : this.today - 1;
      if (todayIndex == this.signInList.length - 1 && this.signInList[todayIndex].status == 3) {
        return '恭喜你已完成连续7天签到';
      } else if (this.signInList[todayIndex].status == 3) {
        return this.signInList[todayIndex + 1].prizes[0].desc;
      } else {
        return this.signInList[todayIndex].prizes[0].desc;
      }
    },
    openRule() {
      this.ruleShow = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.checkIn {
  .advSwiper {
    height: 144px;
    width: 347px;
    margin: 10px auto 19px;
    border-radius: 6px;
    /deep/ .adv-container {
      overflow: hidden;
      border-radius: 6px;
      height: 144px;
      transform: translateZ(0px);
      .my-swipe {
        height: 144px;
        .adv-swiper-slide {
          height: 144px !important;
          width: 347px !important;
        }
      }
      img {
        height: 100%;
      }
    }
    /deep/ img {
      height: 100%;
      object-fit: fill;
    }
  }
  .signBox {
    padding: 0 14px;
    margin-top: 20px;
    .subTitle {
      font-size: 16px;
      color: rgb(51, 51, 51);
      letter-spacing: 0.8px;
      align-items: flex-end;
      margin-bottom: 15px;
      p:last-of-type {
        font-size: 12px;
        color: rgb(153, 153, 153);
        letter-spacing: 0.6px;
        span {
          color: rgb(183, 123, 253);
        }
      }
    }
    .checkInBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .signItem {
        height: 81px;
        width: 79px;
        background-color: rgb(248, 248, 248);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        padding-top: 10px;
        position: relative;
        box-sizing: border-box;
        .signed {
          height: 33px;
          width: 81px;
          position: absolute;
          left: -3px;
          bottom: -2px;
          z-index: 9;
        }
        .title {
          font-size: 12px;
          color: rgb(153, 153, 153);
          position: absolute;
          bottom: 7px;
        }
        .prizesImg {
          height: 45px;
          max-width: 70px;
          /deep/ .laz_com {
            background-color: unset;
            .vanImage {
              background-color: unset;
            }
            .animationImg img {
              width: unset;
            }
          }
        }
        .day {
          position: absolute;
          left: 50%;
          top: -25px;
          transform: translate(-50%, 0);
          font-size: 14px;
          color: rgb(51, 51, 51);
          width: 100%;
          text-align: center;
        }
      }
      .signItem:last-of-type {
        width: 169px;
        position: relative;
        .prizesImg {
          position: absolute;
          left: 0;
          top: 0;
          width: 169px !important;
          max-width: unset !important;
          /deep/ .animationImg {
            display: unset;
            overflow: unset;
          }
          /deep/ .animationImg img {
            width: 169px;
            height: 81px;
          }
        }
        .signed {
          height: 33px;
          width: 171px;
          background: url('./../../../assets/png/sgined.png') no-repeat;
          background-size: 100% 100%;
        }
        .title {
          position: absolute;
          bottom: 7px;
          left: 50%;
          transform: translate(-50%, 0);
        }
        .day {
          position: absolute;
          left: 50%;
          top: -25px;
          transform: translate(-50%, 0);
          font-size: 14px;
          color: rgb(51, 51, 51);
          width: 100%;
          text-align: center;
        }
      }
      .signItemed {
        background: rgb(255, 255, 255);
        border: 2.5px solid rgb(252, 177, 103);
        .day {
          top: -27px;
        }
      }
    }
    .checkInBtn {
      height: 43px;
      width: 322px;
      background-color: rgb(252, 177, 103);
      border-radius: 10px;
      color: white;
      font-size: 17px;
      text-align: center;
      line-height: 43px;
      margin: 38px auto 0;
    }
    .checkedInBtn {
      height: 43px;
      width: 322px;
      background-color: rgb(239, 239, 239);
      border-radius: 10px;
      color: rgb(252, 177, 103);
      font-size: 17px;
      text-align: center;
      line-height: 43px;
      margin: 38px auto 0;
      .signedBtn {
        height: 13.2px;
        width: 17.3px;
        margin-right: 10.2px;
      }
    }
  }
  .ruleDialog {
    .ruleContent {
      height: 225px;
      width: 275px;
      background: linear-gradient(to bottom, rgb(255, 196, 213), rgb(252, 237, 232), rgb(255, 255, 255), rgb(255, 255, 255));
      padding: 16px 20px 0;
      box-sizing: border-box;
      border-radius: 10px;
      .popTitle {
        font-size: 16px;
        color: rgb(68, 68, 68);
        font-weight: 600;
        padding-bottom: 14px;
        text-align: center;
        border-bottom: 1px solid white;
      }
      .content {
        height: 120px;
        overflow-y: auto;
        padding-top: 20px;
        p {
          margin-bottom: 20px;
        }
      }
    }
    .closeBtn {
      text-align: center;
      margin-top: 10px;
      > img {
        width: 32px;
        height: 32px;
        margin: 16px auto 0;
      }
    }
  }
  /deep/ .van-popup {
    background-color: transparent !important;
  }
  .dialog {
    height: 4.43rem;
    border-radius: 0.2rem;
    background: none;

    .dialogContent {
      background: #ffffff;
      width: 7.04rem;
      height: 3.52rem;
      border-radius: 0.2rem;

      .dialogTitle {
        font-size: 0.4rem;
        color: rgb(132, 132, 132);
        display: flex;
        justify-content: center;
        padding-top: 0.3rem;
      }

      .dialogText {
        font-size: 0.38rem;
        color: rgb(100, 100, 100);
        padding-top: 0.32rem;
        font-weight: bold;
        text-align: center;

        .vip {
          font-size: 0.45rem;
          color: rgb(254, 127, 15);
        }
      }

      .btnGroup {
        width: 100%;
        display: flex;
        position: absolute;
        top: 2.1rem;
        display: flex;
        justify-content: center;

        .toBinding {
          //margin-left: 0.76rem;
        }

        .toPrepaid {
          margin-left: 0.22rem;
        }

        .btn {
          width: 2.76rem;
          height: 0.9rem;
        }
      }
    }

    .closeBtn {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: 99999;

      img {
        width: 0.71rem;
        height: 0.71rem;
      }
    }
  }
}
</style>
